import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import MenuIcon from '../vendors/fontAwesome/svgs/bars.svg';
import CloseMenuIcon from '../vendors/fontAwesome/svgs/times.svg';
import Menu from './menu';
import MobileMenu from './mobileMenu';
// import '../scripts/main.js';
import logo from '../images/logo_purple_300x300.png';
console.log(logo);

const imgStyle = {
  width: '50px',
  height: '50px'
}

const Header = () => (
    <div className="navbar">
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`
          }}
        >
          <img src={logo} style={imgStyle} />
        </Link>

      <Menu />
      <MobileMenu />
    </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
