import React from 'react';
import LinkedinIcon from '../vendors/fontAwesome/svgs/linkedin.svg';
import GithubIcon from '../vendors/fontAwesome/svgs/github-square.svg';
import MailIcon from '../vendors/fontAwesome/svgs/envelope-square.svg';

const SocialMedia = () => (
  <div className="landing-btn">
    <a href="https://github.com/minteapixel"><GithubIcon className="icon--landing icon--landing--marginright" /></a>
    <a href="https://www.linkedin.com/in/lineileen"><LinkedinIcon className="icon--landing icon--landing--marginright" /></a>
    <a href="mailto:eileen.lin78@gmail.com"><MailIcon className="icon--landing" /></a>
  </div>
);

export default SocialMedia;