import React from 'react';
import { Link } from 'gatsby';

const Menu = () => (
  <nav className="menu">
    <Link activeClassName="nav-link--active" className="nav-link" to="/work/">Work</Link>
    <Link activeClassName="nav-link--active" className="nav-link" to="/about/">About</Link>
    <Link activeClassName="nav-link--active" className="nav-link" to="/contact/">Contact</Link>
    <a className="nav-link" href={'https://blog.lineileen.com/'}>Blog</a>
  </nav>
);

export default Menu;