import React from 'react';
import { Link } from 'gatsby';
import SocialMedia from '../components/socialmedia';
import '../styles/index.scss';

const Footer = () => (
	<footer className="footer">
		<SocialMedia />
		<p>Developed by Eileen Lin © 2018-2019</p>
	</footer>
);

export default Footer;