import { Link } from 'gatsby'
import React, { Component } from 'react'

import OpenMenuIcon from '../vendors/fontAwesome/svgs/bars.svg';
import CloseMenuIcon from '../vendors/fontAwesome/svgs/times.svg';

class MobileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }
   
  toggleMenu = () => {
    this.setState({
      visible: !this.state.visible
    });
  }
  
  render() {
    return (
      <nav className="menu-mob__outer">
        <OpenMenuIcon
          className="icon menu-mob__icon menu-mob__open"
          onClick={this.toggleMenu}
        />
        {this.state.visible &&
          (
            <div className="menu-mob">
              <CloseMenuIcon className="icon menu-mob__icon mobmenu__close-btn" onClick={this.toggleMenu} />
              <Link activeClassName="nav-link--active" className="menu-mob__link" to="/work/">Work</Link>
              <Link activeClassName="nav-link--active" className="menu-mob__link" to="/about/">About</Link>
              <Link activeClassName="nav-link--active" className="menu-mob__link" to="/contact/">Contact</Link>
              <a className="menu-mob__link" href={'https://blog.lineileen.com/'}>Blog</a>
            </div>
          )
        }
      </nav>
    )
  }
}

export default MobileMenu;
